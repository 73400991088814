import React from 'react';

export default class IfError extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false, 
            error: [],
            errorInfo: []
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        console.error('EVE ERROR:' + error);
        this.setState({hasError: true});
        this.setState({error: error});
        this.setState({errorInfo: errorInfo});
    }

    render() {

        function handleClick() {
            localStorage.removeItem('eve-token');
            window.location.reload();
        }



        if (this.state.hasError) {
            return (
                <div className="w-full h-full flex items-center justify-center font-mono bg-blue p-8">
                    <div className="container">
                        <h6 className="mb-4">{String(this.state.error)}</h6>
                        <p className="mb-4">It looks like something has broken. Check the console for more information.</p>
                        {this.state.errorInfo.length !==0 && console.log(this.state.errorInfo)}
                        <hr className="my-8"/>
                        <p className="mb-4">This could be an authorisation error. Trying logging out, then logging in again. If the issue persists, contact support@wearewibble.com</p>
                        <span className="inline-block border px-4 py-2 cursor-pointer hover:bg-white hover:text-blue" onClick={handleClick}>Log out</span>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}