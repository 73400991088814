import React from 'react'
import {useUser} from '../context/user-context'

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))


function App() {
    const {user, checkingAuth, error} = useUser()

    console.log(error);

    const loading = (<div className="loading-wrapper"><div className="inline-loading" /></div>);

    return (
        <React.Suspense fallback={loading}>
            {checkingAuth 
                ? loading
                : (user !== null && user.token !== undefined)
                     ? <AuthenticatedApp user={user} /> 
                     : <UnauthenticatedApp user={user} error={error} />
            }
        </React.Suspense>
    )
}

export default App;