import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import AppProviders from './context'
import IfError from "./components/IfError.js";

ReactDOM.render(
  <AppProviders>
      <IfError>
          <App />
      </IfError>
  </AppProviders>,
  document.getElementById('root'),
)