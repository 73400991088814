import React from 'react'
import { navigate} from "@reach/router";

const AuthContext = React.createContext()

const AUTH = process.env.REACT_APP_API_AUTH;
const AUTH_VALID = process.env.REACT_APP_API_AUTH_VALID;


function AuthProvider(props) {

    let nullObject = {user: null};
    const [data, setData] = React.useState(nullObject);

    const checkLogin = () => {
        let user = localStorage.getItem('eve-token');
        user = JSON.parse(user);

        if(user){
            let loadingObject = {user: user, checkingAuth: true};
            setData(loadingObject);

            fetch(AUTH_VALID, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + user.token }})
                .then(response => response.json())
                .then(data => {
                    if(data.code === "jwt_auth_valid_token"){
                        //all good
                        let userObject = {user: user, checkingAuth: false};
                        setData(userObject);
                    }
                    else {
                        //oh no - logout
                        //setData(nullObject);
                        let errorObject = {user: null, checkingAuth: false, error: data.message};
                        setData(errorObject);
                    }
                }).catch(function(error) {
                    let message = ("Error: " + JSON.stringify(error));
                    let errorObject = {user: null, checkingAuth: false, error: message};
                    setData(errorObject);
                });
        }
    };

    const login = (props) => {

        const {username, password} = props;

        fetch(AUTH, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({username: username, password: password})})
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('eve-token', JSON.stringify(data));
                setData(prev => ({...prev, user: data}));

                //check if remember was chosen + data.code (error) is false, then save username for next time
                if(!data.code) {
                    localStorage.setItem('eve-remember-username', username);
                    localStorage.setItem('eve-remember-nicename', data.user_display_name);
                }
            })
            .catch(function(error) {
                let message = ("Error: " + JSON.stringify(error));
                setData(prev => ({...prev, user: {code : true, message: message}}));

            });

        navigate('/');
    };

    const logout = () => {

      localStorage.removeItem('eve-token');
      setData(prev => ({...prev, user: null}));

  };

    React.useEffect(()=> {
        checkLogin();
    //eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider value={{data, login, logout}} {...props} />
    )
}

const useAuth = () => React.useContext(AuthContext);

export {AuthProvider, useAuth}